import React from 'react';
import { graphql, Link } from 'gatsby';
import SEO from '../components/SEO';
import Layout from '../layouts/index';
import PrismicRecipeNav from '../components/PrismicRecipeNav';
import HelmetExport from 'react-helmet/es/Helmet';

const Recipe = ({ data }) => {
  const {
    recipe,
    category
  } = data;
  const {
    title,
    body,
  } = recipe.data;
  const image = recipe.data.featureimg || [];
  return (
    <Layout bodyClass="page-recipe">
      <SEO title={title.text} description={title.text} keywords={title.text}/>
      <div className="intro">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h1>Recipes</h1>
            </div>
          </div>
        </div>
      </div>
      <div className="container">

        <PrismicRecipeNav />
        <hr/>
        <div className="row">
          <Link to={`/recipes/${category.uid}/`} className=""
                aria-label={`Back to ${category.data.title.text}`}>
            <i style={{ fontSize: '2em' }}
               className="fas fa-arrow-circle-left"/>
          </Link>
          <div className="col-12 col-md-6">
            <div className="recipe recipe-single">
              <h1 className="title">{recipe.data.title.text}</h1>
              <br/>
              <div className="content" dangerouslySetInnerHTML={{ __html: body.html }}/>
            </div>
          </div>
          <div className="col-12 col-md-5 pl-2 ">
            <img alt={title} style={{
              objectFit: 'cover',
              width: '90%',
            }}
                 className="img-thumbnail"
                 src={image.url}
                 hidden={!(image.url)}/>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
query($id: String!, $categoryUid: String!) {
  recipe: prismicRecipe(id: {eq: $id}) {
    id
    uid
    data {
      title {
        text
      }
      featureimg {
        url
      }
      body {
        html
      }
    }
  }
  category: prismicRecipeType(uid: {eq: $categoryUid}) {
    id
    uid
    data {
      coverimgsize
      title {
        text 
      }
    }
  }
}`;

export default Recipe;
